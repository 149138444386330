<template>
  <div>
    <client-only>
      <div
        id="contentScrollWrapper"
        ref="contentScrollWrapper"
        class="page-tables-wrapper scrollbar"
        :class="{ '--side-panel': isSidePanelOpenedAfter210 }"
      >
        <div class="shadow-effect" :class="{ '--shadow': isScrolling }" />
        <LayoutMainHeaderInteractionBar class="page-tables-header">
          <template v-if="$slots.title" #title>
            <slot name="title" />
          </template>
          <template v-if="drawerPosition" #navigation-toolbar>
            <CommonsNavigationTopBar :drawer-position="drawerPosition" :state="state" />
          </template>

          <template v-if="$slots['top-button']" #cta-button>
            <slot name="top-button" />
          </template>

          <template #items>
            <slot name="top-content" />
          </template>
        </LayoutMainHeaderInteractionBar>
        <template v-if="hasAnyItem === true || hasAnyItem === null">
          <CommonsTablesMainTopToolbar
            class="page-tables-filters"
            :workable-width="workableWidth"
            :state="state"
            :config="configFilters"
            :is-loading="isLoading"
          >
            <template #speFilters>
              <slot name="filters" />
            </template>
          </CommonsTablesMainTopToolbar>
          <slot name="panels" />
        </template>

        <template v-else>
          <slot name="first-item" />
        </template>
      </div>
    </client-only>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  isSidePanelOpened: boolean;
  state: string;
  drawerPosition: string | null;
  configFilters: string[];
  workableWidth: number;
}>();

const isSidePanelOpenedAfter210 = ref(props.isSidePanelOpened);
watch(
  () => props.isSidePanelOpened,
  () => {
    setTimeout(() => {
      // we wait for the end of the close animation before hidding the SidePanel
      isSidePanelOpenedAfter210.value = props.isSidePanelOpened;
    }, 210);
  },
);

// Scroll effect
const isScrolling = ref(false);
let hasEvent = false;

const contentScrollWrapper = ref<HTMLElement | null>(null);
watch(contentScrollWrapper, () => {
  if (!hasEvent) {
    contentScrollWrapper.value?.addEventListener("scroll", updateIsScrolling);
    hasEvent = true;
  }
});

function updateIsScrolling() {
  const element = document.getElementById("contentScrollWrapper");
  isScrolling.value = (element?.scrollTop ?? 0) !== 0;
}

onUnmounted(() => {
  contentScrollWrapper.value?.removeEventListener("scroll", updateIsScrolling);
  hasEvent = false;
});

// Has any item

const storeComposable = useStoreComposable();
const activeStore = computed(() => storeComposable.activeStore(props.state));

const hasAnyItem = computed(() => {
  const hasAnyItem = activeStore.value?.hasAnyItem;
  if (hasAnyItem !== undefined) {
    return activeStore.value?.hasAnyItem;
  }
  return null; // true
});

const isLoading = computed(() => {
  return activeStore.value?.isLoading ?? false;
});
</script>
